import { extendTheme } from '@chakra-ui/react';

export default extendTheme({
  initialColorMode: 'dark',
  useSystemColorMode: false,
  fonts: {
    heading: `Satoshi, sans-serif`,
    body: `Satoshi, sans-serif`,
  },
  styles: {
    global: {
      html: {
        bg: '#0A0A0A',
        scrollBehavior: 'smooth',
        scrollPaddingTop: {
          base: '52px',
          md: '72px',
        },
      },
      body: {
        bg: '#0A0A0A',
        color: 'white',
        overflowX: 'hidden',
      },
    },
  },
  components: {
    Input: {
      baseStyle: {
        field: {
          _placeholder: {
            color: '#757575',
          },
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          bg: '#1A1B1F',
          color: 'white',
          borderRadius: '16px',
          overflow: 'hidden',
          border: '1px solid #404040',
          padding: '0',
        },
        item: {
          bg: '#22242B',
          height: '48px',
          gap: '8px',
        },
        divider: {
          my: '0',
        },
      },
    },
    Link: {
      baseStyle: {
        textDecoration: 'none',
        _hover: {
          textDecoration: 'none',
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 'full',
        fontWeight: 400,
        fontSize: '14px',
        padding: '10px 16px',
        lineBreak: 'anywhere',
        whiteSpace: 'normal',
      },
      defaultProps: {
        size: 'm',
        variant: 'primary',
      },
      sizes: {
        m: {
          fontSize: '14px',
          padding: '10px 16px',
        },
        s: {
          h: '28px',
          fontSize: '14px',
          padding: '4px 8px',
        },
        xs: {
          whiteSpace: 'nowrap',
          h: '20px',
          fontSize: '12px',
          padding: '2px 8px',
        },
      },
      variants: {
        primary: {
          bg: '#2B50FF',
          color: 'white',
          _hover: {
            bg: '#0420A7',
            _disabled: {
              color: '#757575',
              bg: '#333333',
            },
          },
          _disabled: {
            opacity: 1,
            color: '#757575',
            bg: '#333333',
          },
        },
        secondary: {
          bg: 'rgba(224, 232, 255, 0.2)',
          color: 'white',
          _hover: {
            bg: 'rgba(224, 232, 255, 0.1)',
            _disabled: {
              color: '#757575',
              bg: 'rgba(224, 232, 255, 0.1)',
            },
          },
          _disabled: {
            opacity: 1,
            color: '#757575',
            bg: 'rgba(224, 232, 255, 0.05)',
          },
        },
        'primary-lg': {
          color: 'white',
          border: '1.5px solid transparent',
          bg: 'linear-gradient(#2B50FF, #2B50FF) padding-box, linear-gradient(142.14deg, #F67BE8 -47.7%, #1239F0 100%) border-box',
          _hover: {
            bg: 'linear-gradient(#0420A7, #0420A7) padding-box, linear-gradient(142.14deg, #F67BE8 -47.7%, #1239F0 100%) border-box',
            _disabled: {
              color: '#757575',
              bg: 'linear-gradient(#333333, #333333) padding-box, linear-gradient(142.14deg, #F67BE8 -47.7%, #1239F0 100%) border-box',
            },
          },
          _disabled: {
            opacity: 1,
            color: '#757575',
            bg: 'linear-gradient(#333333, #333333) padding-box, linear-gradient(142.14deg, #F67BE8 -47.7%, #1239F0 100%) border-box',
          },
        },
        'dark-lg': {
          color: 'white',
          border: '1.5px solid transparent',
          bg: 'linear-gradient(#0A0A0A, #0A0A0A) padding-box, linear-gradient(142.14deg, #F67BE8 -47.7%, #1239F0 100%) border-box',
          _hover: {
            bg: 'linear-gradient(#0A0A0A, #0A0A0A) padding-box, linear-gradient(142.14deg, #F67BE8 -47.7%, #1239F0 100%) border-box',
            _disabled: {
              color: '#757575',
              bg: 'linear-gradient(#333333, #333333) padding-box, linear-gradient(142.14deg, #F67BE8 -47.7%, #1239F0 100%) border-box',
            },
          },
          _disabled: {
            opacity: 1,
            color: '#757575',
            bg: 'linear-gradient(#333333, #333333) padding-box, linear-gradient(142.14deg, #F67BE8 -47.7%, #1239F0 100%) border-box',
          },
        },
        'primary-highlighted': {
          bg: '#2B50FF',
          color: 'white',
          backgroundImage:
            'linear-gradient(#2B50FF, #2B50FF), linear-gradient(175deg, #F67BE8, #1239F0) 30%',
          border: '1.5px solid transparent',
          backgroundClip: 'content-box, border-box',
          backgroundOrigin: 'border-box',

          _hover: {
            bg: '#0420A7',
            backgroundImage:
              'linear-gradient(#0420A7, #0420A7), linear-gradient(175deg, #F67BE8, #1239F0) 30%',
            border: '1.5px solid transparent',
            backgroundClip: 'content-box, border-box',
            backgroundOrigin: 'border-box',
            _disabled: {
              color: '#757575',
              bg: '#333333',
              backgroundImage: 'none',
            },
          },
          _disabled: {
            opacity: 1,
            color: '#757575',
            bg: '#333333',
            borderColor: '#333333',
            backgroundImage: 'none',
          },
        },
      },
    },
  },
});
